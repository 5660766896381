
<template>
    <div class="MyNFT">
        <div class="tabs">
            <div class="center connectBoxW">
                <div @click="tabIndex = 0">
                    <div :class=" tabIndex == 0 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 0 ? '' : 'no'">Common Planet</p>
                </div>
                <div @click="tabIndex = 1">
                    <div :class=" tabIndex == 1 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 1 ? '' : 'no'">Unique Planet</p>
                </div>
                <div @click="tabIndex = 2">
                    <div :class=" tabIndex == 2 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 2 ? '' : 'no'">Exclusive Star Sign</p>
                </div>
            </div>
        </div>
        <div class="nftList">
            <div v-if="tabIndex == 0" class="center connectBoxW">
                <!-- <div class="nodata" v-if="level0MYList.length+level0MYStakingList.length+level0MYMarketList.length == 0">
                    <img class="nodataImg" v-if="(!myDataLoading0 || level0MYList.length == 0) && (!myStakingDataLoading0 || level0MYStakingList.length == 0) && (!myMarketDataLoading0 || level0MYMarketList.length == 0)" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="(!myDataLoading0 || level0MYList.length == 0) && (!myStakingDataLoading0 || level0MYStakingList.length == 0) && (!myMarketDataLoading0 || level0MYMarketList.length == 0)">No data yet</p>
                    <p v-if="(myDataLoading0 || level0MYList.length == 0) && (myStakingDataLoading0 || level0MYStakingList.length == 0) && (myMarketDataLoading0 || level0MYMarketList.length == 0)">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div> -->
                <ul :class="level0MYList.length+level0MYStakingList.length+level0MYMarketList.length <= 3 ? 'li3' : ''">
                    <li v-for="(item) in level0MYStakingList" :key="item.NFTId" class="liActive">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        <v-LoadingWarp style="margin-right:10px;" :imgSize="1"></v-LoadingWarp> Loading . . .
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <v-LoadingWarp style="margin-right:10px;" :imgSize="1"></v-LoadingWarp> Loading . . .
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" class="bgA" :style="{opacity:(!item.UnstakingLoading ? '1' : '.5')}" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest " @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                    <li v-for="(item) in level0MYMarketList" :key="item.NFTId" class="liActive">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        <v-LoadingWarp style="margin-right:10px;" :imgSize="1"></v-LoadingWarp> Loading . . .
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <v-LoadingWarp style="margin-right:10px;" :imgSize="1"></v-LoadingWarp> Loading . . .
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" class="bgA" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest" @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                    <li v-for="(item) in level0MYList" :key="item.NFTId">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        <v-LoadingWarp style="margin-right:10px;" :imgSize="1"></v-LoadingWarp> Loading . . .
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <v-LoadingWarp style="margin-right:10px;" :imgSize="1"></v-LoadingWarp> Loading . . .
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest" @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="tabIndex == 1" class="center connectBoxW">
                <!-- <div class="nodata" v-if="level1MYList.length+level1MYStakingList.length+level1MYMarketList.length == 0">
                    <img class="nodataImg" v-if="(!myDataLoading1 || level1MYList.length == 0) && (!myStakingDataLoading1 || level1MYStakingList.length == 0) && (!myMarketDataLoading1 || level1MYMarketList.length == 0)" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="(!myDataLoading1 || level1MYList.length == 0) && (!myStakingDataLoading1 || level1MYStakingList.length == 0) && (!myMarketDataLoading1 || level1MYMarketList.length == 0)">No data yet</p>
                    <p v-if="myDataLoading1 && myStakingDataLoading1 && myMarketDataLoading1">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div> -->
                <ul :class="level1MYList.length+level1MYStakingList.length+level1MYMarketList.length <= 3 ? 'li3' : ''">
                    <li v-for="(item) in level1MYStakingList" :key="item.NFTId" class="liActive">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" class="bgA" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest" @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                    <li v-for="(item) in level1MYMarketList" :key="item.NFTId" class="liActive">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest" @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                    <li v-for="(item) in level1MYList" :key="item.NFTId">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest" @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="tabIndex == 2" class="center connectBoxW">
                <!-- <div class="nodata" v-if="level2MYList.length+level2MYStakingList.length+level2MYMarketList.length == 0">
                    <img class="nodataImg" v-if="(!myDataLoading2 || level2MYList.length == 0) && (!myStakingDataLoading2 || level2MYStakingList.length == 0) && (!myMarketDataLoading2 || level2MYMarketList.length == 0)" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="(!myDataLoading2 || level2MYList.length == 0) && (!myStakingDataLoading2 || level2MYStakingList.length == 0) && (!myMarketDataLoading2 || level2MYMarketList.length == 0)">No data yet</p>
                    <p v-if="myDataLoading2 && myStakingDataLoading2 && myMarketDataLoading2">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div> -->
                <ul :class="level2MYList.length+level2MYStakingList.length+level2MYMarketList.length <= 3 ? 'li3' : ''">
                    <li v-for="(item) in level2MYStakingList" :key="item.NFTId" class="liActive">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" class="bgA" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest" @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                    <li v-for="(item) in level2MYMarketList" :key="item.NFTId" class="liActive">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest" @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                    <li v-for="(item) in level2MYList" :key="item.NFTId">
                        <div class="nftcard">
                            <div class="nftimg">
                                <!-- <img src="../assets/Mint/commonbox.png" alt=""> -->
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo"  @click="Details(item)">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p v-if="item.type != 1" class="assets" :style="{marginBottom:(item.type == 0 ? '0px' : '15px')}">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p v-if="item.type == 2" class="reward">
                                    Staking Rewards: 
                                    <br v-if="pool0Info.xRate != 10000"/><span v-if="pool0Info.xRate != 10000">{{item.stakingRewardSPACE.toFixed(6)}} {{tokenSymbol}}</span>
                                    <br v-if="pool0Info.xRate != 0"/><span v-if="pool0Info.xRate != 0">{{item.stakingRewardxSPACE.toFixed(6)}} {{xtokenSymbol}}</span>
                                    <br v-if="poolLp0Inof.isExtra"/><span v-if="poolLp0Inof.isExtra">{{item.stakingRewardLpOne.toFixed(6)}} {{poolLp0Inof.lpOneSymbol}}</span>
                                    <br v-if="poolLp0Inof.isTwoExtra"/><span v-if="poolLp0Inof.isTwoExtra">{{item.stakingRewardLpTwo.toFixed(6)}} {{poolLp0Inof.lpTwoSymbol}}</span>
                                    <br v-if="poolLp0Inof.isThrExtra"/><span v-if="poolLp0Inof.isThrExtra">{{item.stakingRewardLpThr.toFixed(6)}} {{poolLp0Inof.lpThrSymbol}}</span>
                                </p>
                                <p v-if="item.type == 1" class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="btns">
                            <p v-if="item.type != 2" :style="{opacity:(item.type == 0 ? '1' : '.5')}" @click="staking(item)">{{item.stakingLoading ? 'Processing' : 'Staking'}}</p>
                            <p v-if="item.type == 2" @click="Unstaking(item)">{{item.UnstakingLoading ? 'Processing' : 'Unstaking'}}</p>
                            <p v-if="item.type != 1" :style="{opacity:(item.type == 0 && !item.stakingLoading ? '1' : '.5')}" @click="Sell(item)">Sell</p>
                            <p v-if="item.type == 1" @click="Delist(item)">{{item.marketLoading ? 'Processing' : 'Delist'}}</p>
                        </div>
                        <div v-if="item.type == 2" class="harvest" @click="Harvest(item)">
                            <p>{{harvestAllLoading ? 'Processing' : 'Harvest'}}</p>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="messageBox" v-if="messageBoxShow">
            <div class="connectBox connectBoxW">
                <div v-if="messageType == 'sellPlanet'" class="message0" @click.stop>
                    <h1>
                        Sell {{this.sellItem.level == 0 ? "Common Planet" : this.sellItem.level == 1 ? "Unique Plane" : "Exclusive Star Sign"}}
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="sellnft">
                        <p style="padding-bottom:20px;">Price:</p>
                        <div style="width: 100%; margin-left: 0; margin-bottom: 20px;">
                            <input type="text" v-model="sellPriceVal" placeholder="Place enter the sale price" :disabled="sellItem.marketLoading">
                            <p>{{tokenSymbol}}</p>
                        </div>
                    </div>
                    <div class="messagebtn" style="margin-top: 24px;" @click="sellSure">{{sellItem.marketLoading ? 'Processing' : 'Sell'}}</div>
                </div>
                <div v-if="messageType == 'tips'" class="message0" @click.stop>
                    <h1>
                        Tips
                        <i class="el-icon-close" @click="closemessage"></i>
                    </h1>
                    <div class="sellnft">
                        <p style="padding-bottom:20px;"><i class="el-icon-warning" style="color:#F1E74F;"></i> Unstaking/harvesting will harvest all the rewards.</p>
                        
                    </div>
                    <div class="btnbox">
                        <div class="closebtn" @click="closemessage">CANCEL</div>
                        <div class="confirmbtn" @click="Unstakingharvesting">OK </div>
                    </div>
                    <!-- <div class="messagebtn" style="margin-top: 24px;" @click="sellSure">Sell <i v-if="this.sellItem.marketLoading" class="el-icon-loading"></i></div> -->
                </div>
            </div>
        </div>

        <v-Footer></v-Footer>
    </div>
</template>

<script>
import {
    judgeNetwork,getTokenSymbol,getApproved,setNFTApprove,
    nftBalanceOf,tokenOfOwnerByIndex,starMeta,cateURI,
    getpoolInfo,getpoolLpInfo,getUserStakingNFTAmount,userNFTs,nftPendingStar,getUserTokensLength,userTokens,marketTokenInfo,
    enterStakingNFT,leaveStakingNFT,
    setTokenSale,
} from '../utils/space.js'
export default {
    name: "MyNFT",
    data() {
        return {
            userAddress:'',tokenSymbol:'',xtokenSymbol:'',tokenAddress:'',xtokenAddress:'',nftAddress:'',
            tabIndex: 0,
            poolInof:{},poolLp0Inof:{},
            myDataLoading0:true,myStakingDataLoading0:true,myMarketDataLoading0:true,
            myDataLoading1:true,myStakingDataLoading1:true,myMarketDataLoading1:true,
            myDataLoading2:true,myStakingDataLoading2:true,myMarketDataLoading2:true,
            level0MYList:[],level1MYList:[],level2MYList:[],
            level0MYStakingList:[],level1MYStakingList:[],level2MYStakingList:[],
            level0MYMarketList:[],level1MYMarketList:[],level2MYMarketList:[],
            messageBoxShow:false,messageType:'',
            unstakingItem:{},harvestAllLoading:false,
            sellItem:{},sellPriceVal:null,sellLoading:false
        }
    },
    mounted() { 
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.nftInfoList = window.nftImgData.cardList
        this.tokenAddress = window.contractAddr.tokenAddress
        this.xTokenAddress = window.contractAddr.xTokenAddress
        this.nftAddress = window.contractAddr.nftAddress
        this.logicalAddress = window.contractAddr.logicalAddress
        this.marketAddress = window.contractAddr.marketAddress
        this.farmAddress = window.contractAddr.farmAddress
        this.farmLibAddress = window.contractAddr.farmLibAddress
        this.farmPendingAddress = window.contractAddr.farmPendingAddress
        getpoolInfo(this.farmLibAddress,0,this.userAddress).then(pool0Info => {
            pool0Info.xSPACERate = pool0Info.xRate/10000
            pool0Info.SPACERate = 1 - pool0Info.xRate/10000
            this.pool0Info = pool0Info
        })
        getpoolLpInfo(this.farmLibAddress,0,this.userAddress).then(poolLp0Inof => {
            this.getlpTokenSymbol(poolLp0Inof.lpAddr).then(symbol => {
                poolLp0Inof.lpOneSymbol = symbol
            })
            this.getlpTokenSymbol(poolLp0Inof.lpTwoAddr).then(symbol => {
                poolLp0Inof.lpTwoSymbol = symbol
            })
            this.getlpTokenSymbol(poolLp0Inof.lpThrAddr).then(symbol => {
                poolLp0Inof.lpThrSymbol = symbol
            })
            this.poolLp0Inof = poolLp0Inof
        })
        if (this.userAddress) {
            this.getMyNFT()
            this.getMyStakingNFT()
            this.getMyMarketplaceNFT()   
        }
    },
    methods: {
        staking(item){
            if(item.type != 0){
                return false
            }
            if (item.stakingLoading) {
                return
            }
            item.stakingLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    item.stakingLoading = false
                    return false
                }
                getApproved(this.nftAddress,item.NFTId,this.userAddress).then(approvedRes => {
                    if (approvedRes == 0 || approvedRes.toUpperCase() != this.farmAddress.toUpperCase()) {
                        setNFTApprove(this.nftAddress,this.farmAddress,item.NFTId,this.userAddress).then(res => {
                            this.enterStakingNFT(item)
                        }).catch(error => {
                            item.stakingLoading = false
                        })
                    } else {
                        this.enterStakingNFT(item)
                    }
                })
            })
        },
        enterStakingNFT(item){
            //console.log(this.farmAddress,item.NFTId,this.userAddress)
            enterStakingNFT(this.farmAddress,item.NFTId,this.userAddress).then(res => {
                this.getNFTPendingStar(item)
                item.type = 2
                item.stakingLoading = false
            }).catch(error => {
                console.log(error)
                item.stakingLoading = false
            })
        },
        Sell(item){
            if (item.type == 0) {
                this.messageBoxShow = true
                this.messageType = 'sellPlanet'
                this.sellItem = item
                //console.log(this.sellItem)
            }
            
        },
        sellSure(){
            // sellPriceVal
            if (this.sellItem.marketLoading) {
                return
            }
            this.sellItem.marketLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.sellItem.marketLoading = false
                    return false
                }
                getApproved(this.nftAddress,this.sellItem.NFTId,this.userAddress).then(approvedRes => {
                    if (approvedRes == 0 || approvedRes.toUpperCase() != this.marketAddress.toUpperCase()) {
                        setNFTApprove(this.nftAddress,this.marketAddress,this.sellItem.NFTId,this.userAddress).then(res => {
                            this.setTokenSale(true)
                        },error => {
                            this.sellItem.marketLoading = false
                        }).catch(error => {
                            this.sellItem.marketLoading = false
                        })
                    } else {
                        this.setTokenSale(true)
                    }
                })
            })
        },
        Delist(item){
            this.sellItem = item
            if (this.sellItem.marketLoading) {
                return
            }
            this.sellItem.marketLoading = true
            this.sellPriceVal = 0
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.sellItem.marketLoading = false
                    return false
                }
                this.setTokenSale(false)
            })
            
            
        },
        
        setTokenSale(type){
            setTokenSale(this.marketAddress,this.sellItem.NFTId,type,this.sellPriceVal,this.userAddress).then(res => {
                if (type) {
                    this.sellItem.type = 1
                    this.sellItem.price = Number((this.sellPriceVal))
                    this.sellPriceVal = null
                } else {
                    this.sellItem.type = 0
                    this.sellItem.price = 0
                }
                this.sellItem.marketLoading = false
                this.messageBoxShow = false
                this.messageType = ''
            }).catch(error => {
                this.sellItem.marketLoading = false
                this.messageBoxShow = false
                this.messageType = ''
            })
        },
        Unstaking(item){
            if(item.type != 2){
                return false
            }
            if (this.unstakingItem.UnstakingLoading) {
                return false
            }
            judgeNetwork().then(res => {
                if (res == 0) {
                    return false
                }
                this.messageBoxShow = true
                this.messageType = 'tips'
                this.unstakingItem = item
            })
            
        },
        Harvest(){
            if (this.harvestAllLoading) {
                return false
            }
            judgeNetwork().then(res => {
                if (res == 0) {
                    return false
                }
                this.messageBoxShow = true
                this.messageType = 'tips'
                this.unstakingItem = {NFTId:0}
            })
        },
        Unstakingharvesting(){
            if (this.unstakingItem.NFTId == 0 && this.harvestAllLoading) {
                //console.log(1)
                return false
            } else {
                //console.log(1)
                this.harvestAllLoading = true
            }
            if(this.unstakingItem.NFTId != 0 && this.unstakingItem.type != 2){
                //console.log(1)
                return false
            }
            if (this.unstakingItem.NFTId != 0 && this.unstakingItem.type == 2 && this.unstakingItem.UnstakingLoading) {
                //console.log(1)
                return flase
            }
            if (this.unstakingItem.NFTId != 0 && this.unstakingItem.type == 2 && !this.unstakingItem.UnstakingLoading) {
                //console.log(1)
                this.unstakingItem.UnstakingLoading = true
            }
            this.leaveStakingNFT()
        },
        leaveStakingNFT(){
            this.messageBoxShow = false
            this.messageType = ''
            //console.log(this.farmAddress,this.unstakingItem.NFTId,this.userAddress)
            leaveStakingNFT(this.farmAddress,this.unstakingItem.NFTId,this.userAddress).then(res => {
                if (this.unstakingItem.NFTId == 0) {
                    this.level0MYStakingList.forEach(item => {
                        item.stakingRewardSPACE = 0
                        item.stakingRewardxSPACE = 0
                        item.stakingRewardLpOne = 0
                        item.stakingRewardLpTwo = 0
                        item.stakingRewardLpThr = 0
                    })
                    this.level1MYStakingList.forEach(item => {
                        item.stakingRewardSPACE = 0
                        item.stakingRewardxSPACE = 0
                        item.stakingRewardLpOne = 0
                        item.stakingRewardLpTwo = 0
                        item.stakingRewardLpThr = 0
                    })
                    this.level2MYStakingList.forEach(item => {
                        item.stakingRewardSPACE = 0
                        item.stakingRewardxSPACE = 0
                        item.stakingRewardLpOne = 0
                        item.stakingRewardLpTwo = 0
                        item.stakingRewardLpThr = 0
                    })
                    this.harvestAllLoading = false
                    this.unstakingItem = {}
                } else {
                    this.getNFTPendingStar(this.unstakingItem)
                    this.unstakingItem.type = 0
                    this.unstakingItem.UnstakingLoading = false
                    this.harvestAllLoading = false
                    this.unstakingItem = {}
                }
            },err => {
                //console.log(1)
                this.unstakingItem.UnstakingLoading = false
                this.harvestAllLoading = false
                this.unstakingItem = {}
            }).catch(error => {
                //console.log(1)
                this.unstakingItem.UnstakingLoading = false
                this.harvestAllLoading = false
                this.unstakingItem = {}
            })
        },
        getNFTPendingStar(item){
            nftPendingStar(this.farmPendingAddress,item.NFTId,this.userAddress).then(peddingStar => {
                item.stakingRewardSPACE = Number( peddingStar._amountpendingStar/1e18*this.pool0Info.SPACERate )
                item.stakingRewardxSPACE = Number( peddingStar._amountpendingStar/1e18*this.pool0Info.xSPACERate )
                item.stakingRewardLpOne = Number( peddingStar._amountLpPendingStar/1e18 )
                item.stakingRewardLpTwo = Number( peddingStar._amountLpTwoPendingStar/1e18 )
                item.stakingRewardLpThr = Number( peddingStar._amountLpThrPendingStar/1e18 )
            })
        },
        closemessage(){
            this.messageBoxShow = false
            this.messageType = ''
            this.sellPriceVal = null
        },
        Details(item){
            this.$router.push({
                path:'/nftDetail',
                query:{
                    tokenid:item.NFTId,
                    type:item.type
                }
            })
        },
        getMyNFT(){
            this.myDataLoading0 = true
            this.myDataLoading1 = true
            this.myDataLoading2 = true
            nftBalanceOf(this.nftAddress,this.userAddress).then(nftBalanceOf => {
                if (nftBalanceOf == 0) {
                    this.myDataLoading0 = false
                    this.myDataLoading1 = false
                    this.myDataLoading2 = false
                    return false
                }
                for (let i = 0; i < nftBalanceOf; i++) {
                    tokenOfOwnerByIndex(this.nftAddress,i,this.userAddress).then(NFTId => {
                        starMeta(this.logicalAddress,NFTId).then(nftInfo => {
                            cateURI(this.nftAddress,NFTId).then(nftUrl => {
                                var obj = {
                                    type:0,
                                    nftUrl,
                                    cateId:nftInfo.cateId,
                                    NFTId:Number(NFTId),
                                    stakingLoading:false,
                                    marketLoading:false,
                                    select:false,
                                    name:'',
                                    level:nftInfo.level,
                                    multiple:Number(nftInfo.multiple/100).toFixed(2),
                                    assets:Number((nftInfo.price/Math.pow(10,18))*(nftInfo.multiple/100)).toFixed(0),
                                    price:0,
                                    stakingRewardSPACE:0,
                                    stakingRewardxSPACE:0,
                                    stakingRewardLpOne:0,
                                    stakingRewardLpTwo:0,
                                    stakingRewardLpThr:0 ,
                                }
                                this.nftInfoList.forEach((item,index) => {
                                    if (item.cid == nftUrl.split('/')[3]) {
                                        obj.name = item.name
                                    }
                                })
                                if (nftInfo.level == 0) {
                                    this.level0MYList.push(obj)
                                    let arr0 = this.level0MYList
                                    this.dataSort(arr0)
                                }
                                if (nftInfo.level == 1) {
                                    this.level1MYList.push(obj)
                                    let arr1 =  this.level1MYList
                                    this.dataSort(arr1)
                                }
                                if (nftInfo.level == 2) {
                                    this.level2MYList.push(obj)
                                    let arr2 =  this.level2MYList
                                    this.dataSort(arr2)
                                }
                            })
                        })
                    })
                }
                
            })
        },
        getMyStakingNFT(){
            this.myStakingDataLoading0 = true
            this.myStakingDataLoading1 = true
            this.myStakingDataLoading2 = true
            getUserStakingNFTAmount(this.farmAddress,this.userAddress).then(stakingAmount => {
                if (stakingAmount == 0) {
                    this.myStakingDataLoading0 = false
                    this.myStakingDataLoading1 = false
                    this.myStakingDataLoading2 = false
                    return false
                }
                for (let i = 0; i < stakingAmount; i++) {
                    userNFTs(this.farmAddress,i,this.userAddress).then(NFTId => {
                        //console.log(NFTId)
                        nftPendingStar(this.farmPendingAddress,NFTId,this.userAddress).then(peddingStar => {
                            //console.log(peddingStar)
                            //console.log(peddingStar._amountLpPendingStar/1e18)
                            //console.log(peddingStar._amountpendingStar/1e18)
                            starMeta(this.logicalAddress,NFTId).then(nftInfo => {
                                cateURI(this.nftAddress,NFTId).then(nftUrl => {
                                    var obj = {
                                        type:2,
                                        nftUrl,
                                        cateId:nftInfo.cateId,
                                        NFTId:Number(NFTId),
                                        stakingLoading:false,
                                        marketLoading:false,
                                        select:false,
                                        name:'',
                                        level:nftInfo.level,
                                        multiple:Number(nftInfo.multiple/100).toFixed(2),
                                        assets:Number((nftInfo.price/Math.pow(10,18))*(nftInfo.multiple/100)).toFixed(0),
                                        price:0,
                                        stakingRewardSPACE:Number( peddingStar._amountpendingStar/1e18*this.pool0Info.SPACERate ),
                                        stakingRewardxSPACE:Number( peddingStar._amountpendingStar/1e18*this.pool0Info.xSPACERate ),
                                        stakingRewardLpOne:Number( peddingStar._amountLpPendingStar/1e18 ),
                                        stakingRewardLpTwo:Number( peddingStar._amountLpTwoPendingStar/1e18 ),
                                        stakingRewardLpThr:Number( peddingStar._amountLpThrPendingStar/1e18 ),
                                    }
                                    //console.log(obj)
                                    this.nftInfoList.forEach((item,index) => {
                                        if (item.cid == nftUrl.split('/')[3]) {
                                            obj.name = item.name
                                        }
                                    })
                                    if (nftInfo.level == 0) {
                                        this.level0MYStakingList.push(obj)
                                        let arr0 = this.level0MYStakingList
                                        this.dataSort(arr0)
                                    }
                                    if (nftInfo.level == 1) {
                                        this.level1MYStakingList.push(obj)
                                        let arr1 =  this.level1MYStakingList
                                        this.dataSort(arr1)
                                    }
                                    if (nftInfo.level == 2) {
                                        this.level2MYStakingList.push(obj)
                                        let arr2 =  this.level2MYStakingList
                                        this.dataSort(arr2)
                                    }
                                })
                            })
                        })
                    })
                }
            })
        },
        getMyMarketplaceNFT(){
            this.myMarketDataLoading0 = true
            this.myMarketDataLoading1 = true
            this.myMarketDataLoading2 = true
            getUserTokensLength(this.marketAddress,this.userAddress).then(marketNum => {
                if (marketNum == 0) {
                    this.myMarketDataLoading0 = false
                    this.myMarketDataLoading1 = false
                    this.myMarketDataLoading2 = false
                    return false
                }
                for (let i = 0; i < marketNum; i++) {
                    userTokens(this.marketAddress,i,this.userAddress).then(NFTId => {
                        //console.log(NFTId)
                        marketTokenInfo(this.marketAddress,NFTId,this.userAddress).then(userNftPrice => {
                            //console.log(userNftPrice)
                            starMeta(this.logicalAddress,NFTId).then(nftInfo => {
                                cateURI(this.nftAddress,NFTId).then(nftUrl => {
                                    var obj = {
                                        type:1,
                                        nftUrl,
                                        cateId:nftInfo.cateId,
                                        NFTId:Number(NFTId),
                                        stakingLoading:false,
                                        marketLoading:false,
                                        select:false,
                                        name:'',
                                        level:nftInfo.level,
                                        multiple:Number(nftInfo.multiple/100).toFixed(2),
                                        assets:Number((nftInfo.price/Math.pow(10,18))*(nftInfo.multiple/100)).toFixed(0),
                                        price:Number((userNftPrice.price/Math.pow(10,18))),
                                    }
                                    this.nftInfoList.forEach((item,index) => {
                                        if (item.cid == nftUrl.split('/')[3]) {
                                            obj.name = item.name
                                        }
                                    })
                                    if (nftInfo.level == 0) {
                                        this.level0MYMarketList.push(obj)
                                        let arr0 = this.level0MYMarketList
                                        this.dataSort(arr0)
                                    }
                                    if (nftInfo.level == 1) {
                                        this.level1MYMarketList.push(obj)
                                        let arr1 =  this.level1MYMarketList
                                        this.dataSort(arr1)
                                    }
                                    if (nftInfo.level == 2) {
                                        this.level2MYMarketList.push(obj)
                                        let arr2 =  this.level2MYMarketList
                                        this.dataSort(arr2)
                                    }
                                })
                            })
                        })
                    })
                }
            })
        },
        dataSort(arr){
            arr.sort((a ,b) => {
                if (a.NFTId > b.NFTId) {
                    return -1
                } else if (a.NFTId < b.NFTId) {
                    return 1
                }
            })
        },
        getlpTokenSymbol(addr){
            return new Promise((resolve, reject) => {
                if (addr == "0x0000000000000000000000000000000000000000") {
                    resolve(localStorage.getItem('nativeCurrencySymbol'))
                } else {
                    getTokenSymbol(addr).then(symbol => {
                        resolve(symbol)
                    })
                }
            })
        },
    }
}
</script>